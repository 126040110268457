<template>
    <div>
        <nav-bar></nav-bar>
        <service-support-banner>
          使用指南
        </service-support-banner>
        <div class="container-1646">
            <div class="solution-header">模块化自由组合 定义你自己的数据中心 </div>
          <div class="flex-between-wrap solution-space">
            <div  class="solution" v-for="(item,index) in solution" :key="index" :style="{'background-image': `url(${item.images})`}"  @click="goTo(item.arguments)">
              <div class="solution-title">{{item.title}}</div>
              <div class="solution-description">{{item.description}}</div>
            </div>
          </div> 
        </div>  
        <Footer></Footer>
    </div>
  </template>
  
  <script>
  // @ is an alias to /src
  　import { reactive, onMounted,toRefs ,ref,nextTick, computed } from 'vue'
import navBar from '@/components/NavBar'
import Footer from '@/components/Footer'
import ServiceSupportBanner from '@/components/ServiceSupportBanner'
import { useRouter } from 'vue-router';
  export default {
    name: 'Home',
    components: {
      navBar,
      Footer,
      ServiceSupportBanner
    },
  
    setup() { 
        const router = useRouter()
      const useData = reactive({
        solution: [
          {
                title: "SUMGKEE-HCI-Block V2",
                description: "适用于紧凑高密度微型IDC",
                images: require('../assets/images/v2/solution.png'),
                arguments:"v2"
          },
          {
                title: "SUMGKEE-HCI-Block V4",
                description: "适用企业、学校、超市等小规模IDC和超算、 存储节点 ",
                images: require('../assets/images/v4/solution.png'),
                arguments:"v4"
          },
          {
                title: "SUMGKEE-HCI-Block V6",
                description: "适用于企业的中型数据机房以及户外应急IDC ",
                images: require('../assets/images/v6/solution.png'),
                arguments:"v6"
          },
            {
                  title: "SUMGKEE-HCI-Block V8",
                  description: "适用于大型数据机房以及户外应急IDC",
                  images: require('../assets/images/v8/solution.png'),
                  arguments:"v8"
            }
          ],
          TechnicalSpecifications: [
              'IT容量:20~80kW',
              '空调类型:5~30KW分布式空调',
              'UPS类型:机架式或柜式在线UPS单元，20-80KVA ',
              '机柜数量:2~8个',
              '消防模块:机架式消防单元2/4U ',
            '封闭方式:机柜内部独立冷/热通道 全封闭系统 '
          ]
      
      })
      const goTo = (id) => {
      router.push({ path: '/Item', query: { id } })
      }
      const go = (path) => { 
      router.push({ path: path })
      }
    return {
        ...toRefs(useData),
      goTo,
      go
    }
  }
}
  </script>
  <style scoped>
.solution{
  width: 396px;
  height: 650px;
margin: 20px 0px 100px;
background-repeat: no-repeat;
background-size: cover;
cursor: pointer;
}
.solution-space{
  margin: 148px 0px 0px;
}
.solution-title{

font-size: 26px;text-align: center;padding: 373px 0 40px;font-weight: bold;
  color: #53B578;
}
.solution-description{
  font-size: 26px;
  padding:0px 70px;
  text-align: center;
}
.solution-img{
  width: 196px;
  height: 201px;
  margin: 33px auto 40px;display: block;
}
.solution-specialty{
  text-align: center;
  font-size: 20px;
  color: #53B578;
}
.solution-header{
  font-size: 48px;font-weight: bold;padding: 170px 0px 0px;text-align: center;
}
.TechnicalSpecifications{
  height: 538px;background: #F5F5F5;width: 100%;
  margin: 70px 0px 140px;
}
.TechnicalSpecifications-content{
    display: flex;flex-wrap: wrap;
    font-size: 26px;
    padding: 86px 146px 0px;
}
.TechnicalSpecifications-content div:nth-child(1){
   flex: 1;
   padding-bottom: 40px;
}
.TechnicalSpecifications-content div:nth-child(2){
   flex: 1.6;
}
.TechnicalSpecifications-content div:nth-child(3){
   flex: 2.6;
}
.TechnicalSpecifications-text{
  padding: 0px 146px 40px;
  font-size: 26px;
}
</style>
  